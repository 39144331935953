import {Button, Card, Checkbox, Col, Flex, Form, Input, Row, Select, Typography} from "antd";
import {connect} from "react-redux";
import TypographyCountry from "../CurrencySelect/TypographyCountry";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {useForm} from "antd/es/form/Form";
import {SaveOutlined} from "@ant-design/icons";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";

const TeamBackOffice = (props) => {

    const [form] = useForm();

    const  [componentState, setComponentState] = useState({
        teamClientList: [],
        currencyList: []
    });

    const fetchCurrencies = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: false},
            }
        }).then(res => {
            setComponentState(prev => ({...prev, currencyList: res }));
        })
    }

    const fetchClientTeams = () => {
        API.post('commons', `/fetch`, {
            body: {
                context: 'clients',
                fields: ['id', 'nickname'],
                condition: {team_id: props.team_id}
            }
        })
            .then(response => {
                console.log(response);
                setComponentState(prev => ({
                    ...prev,
                    teamClientList: response
                }));
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (props.app_state && props.app_state.current_staff != null) {
            fetchClientTeams();
        }
        fetchCurrencies();
    }, [props.app_state]);

    useEffect(() => {
        if (props.state != null && Object.keys(props.state).length > 0) {
            form.setFieldsValue(props.state);
            console.log(props);
        }
    }, [props.state]);


    return <>
        <Card size={'small'} title={'BackOffice'}>
            <Form name={'back-office-form'} form={form} onFinish={(values) => props.updateState(values)}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item name={'defaultFromCurrency'} label={'Default From Currency'}>
                            <Select placeholder={'-- SELECT --'} optionFilterProp={'alt'} options={(componentState.currencyList || []).map(i => ({
                                label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>,
                                value: i.id,
                                alt: `${i.full_name}-${i.iso_alpha_3}`
                            }))}></Select>
                        </Form.Item>
                        <Form.Item name={'defaultToCurrency'} label={'Default To Currency'}>
                            <Select placeholder={'-- SELECT --'} optionFilterProp={'alt'} options={(componentState.currencyList || []).map(i => ({
                                label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>,
                                value: i.id,
                                alt: `${i.full_name}-${i.iso_alpha_3}`
                            }))}></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item name={'teamClientId'} label={'Team Client'}>
                            <Select placeholder={'-- SELECT --'} options={(componentState.teamClientList || []).map(i => ({
                                label: i.nickname,
                                value: i.id
                            }))}></Select>
                        </Form.Item>
                        <Typography.Link title={'Click here to view client'}></Typography.Link>
                        <Form.Item name={'default_rate'} label={'Default Rate'}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'pendingTransferMaxExpiry'} label={'Pending Transfer Max Expiry (Days)'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item name={'ref_code'} label={'Team Ref Code .'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Form.Item name={'brand_transfer_legacy'} label={'Brand Transfer Legacy'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_searchtransfers'} label={'Brand Sidebar Search Transfer'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_identification_list'} label={'Brand Sidebar Identification List'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_identification_all'} label={'Brand Sidebar Identification All'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_identification_all'} label={'Brand Sidebar Identification All'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_new_transfer'} label={'Brand Sidebar New Transfer'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Form.Item name={'brand_sidebar_multipay'} label={'Brand Sidebar Multipay'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_currencytools'} label={'Brand Sidebar Currency Tools'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_invoicelist'} label={'Brand Sidebar Invoice List'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_sidebar_forwards'} label={'Brand Sidebar Forwards'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_transfer_payeeFirst'} label={'Brand - Transfer Show Payee First'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_ledger'} label={'Brand Ledger'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_paymentRequests'} label={'Brand Sidebar Payment Request List'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Form.Item name={'brand_kyc_hideMaritalStatus'} label={'Brand - Hide Martial Status'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_kyc_hidePlaceOfBirth'} label={'Brand - Hide Place of Birth'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_multipay'} label={'Brand - MultiPay'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_splitpay'} label={'Brand - SplitPay'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name={'brand_create_transfer'} label={'Brand - Create Transfer'} valuePropName={'checked'}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
                <Flex justify={'end'}>
                    <Button type={'primary'} size={'large'} htmlType={'submit'} icon={<SaveOutlined />}>Update</Button>
                </Flex>
            </Form>
        </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TeamBackOffice);