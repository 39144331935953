import {Button, Card, Col, Flex, Form, Input, Row, Select} from "antd";
import {connect} from "react-redux";
import TypographyCountry from "../CurrencySelect/TypographyCountry";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {useForm} from "antd/es/form/Form";
import {SaveOutlined} from "@ant-design/icons";

const TeamDetails = (props) => {

    const [form] = useForm();

    const  [componentState, setComponentState] = useState({
        staffList: []
    });

    const fetchStaffList = () => {
        API.get('staff', `/list/staffID/${props.app_state.current_staff.id}`)
            .then(response => {
                console.log(response);
                setComponentState(prev => ({
                    ...prev,
                    staffList: response
                }));
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (props.app_state && props.app_state.current_staff != null) {
            fetchStaffList();
        }
    }, [props.app_state]);

    useEffect(() => {
        if (props.state != null && Object.keys(props.state).length > 0) {
            form.setFieldsValue(props.state);
            console.log(props);
        }
    }, [props.state]);


    return <>
            <Card size={'small'} title={'Team Details'}>
                <Form name={'team-detail-form'} form={form} onFinish={(values) => props.updateState(values)}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item name={'nickname'} label={'Nickname'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'email'} label={'Email'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'telephone'} label={'Telephone'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'responsible_staff_member'} label={'Responsible of Staff Member'}>
                                <Select placeholder={'Enter Staff List'} options={(componentState.staffList || []).map(i => ({
                                    label: i.nickname,
                                    value: i.id
                                }))}></Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item name={'address_line_1'} label={'Address Line 1'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'address_line_2'} label={'Address Line 2'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'address_state'} label={'State'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'address_suburb'} label={'Suburb'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'address_state'} label={'State'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'address_postcode'} label={'Post Code'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify={'end'}>
                        <Button type={'primary'} size={'large'} htmlType={'submit'} icon={<SaveOutlined />}>Update</Button>
                    </Flex>
                </Form>
            </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);