import {useForm} from "antd/es/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Form, Input, Switch} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import ListTable from "../ListTable/ListTable";

const TeamPaymentRequests = (props) => {

    const [form] = useForm();

    useEffect(() => {
        if (props.state && Object.keys(props.state).length > 0) {
            form.setFieldsValue(props.state);
        }
    }, [props.state]);
    return <>
        <Form name={'team-payment-requests'} form={form} onFinish={(values) => {
            props.updateState(values);
            form.resetFields();
        }}>
            <Form.Item name={'brand_pr_paymentRequestHeading'} label={'Payment Request Heading'}>
                <Input />
            </Form.Item>
            <Form.Item name={'brand_pr_showGst'} label={'Show GST'} valuePropName={'checked'}>
                <Switch />
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} icon={<SaveOutlined />}>Save</Button>
            </Form.Item>
        </Form>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ListTable(TeamPaymentRequests));