import React from 'react';
import {connect} from 'react-redux';

import Datetime from 'react-datetime';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

import CustomInput from '../../CustomInput/CustomInput';
import Button from '../../CustomButtons/Button';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import {API, Storage} from 'aws-amplify';
import {FormattedNumber} from 'react-intl';

import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import TransferDetailRemittanceCommunication from './TransferDetailRemittanceCommunication';

import {fetchEntitybanksListNotDeleted} from '../../../redux/actions/entitybanksaccount';
import {Col, Collapse, Divider, Flex, Form, Image, message, Modal, Row, Select, Tag} from 'antd';
import {TransferAPI} from "../TransfersUtil";
import {CloseOutlined} from "@ant-design/icons";
import {AppUtil} from "../../../AppUtil";
import FileUploader from "../../Uploaders/FileUploader";


const moment = require('moment');

const styles = {
    selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    progressBar: {width: 50},
    description: {color: '#999999'},
    updateProfileButton: {float: 'right'},
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {float: 'right'},
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    tableHeaders: {
        width: 200,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0
    },
    backgroundBlue: {
        backgroundColor: '#348feb20'
    }
};

const TRANSFER_STATUS_COMPLETE_VALUE = {
    1: {value: 10, text: 'Draft'},
    2: {value: 20, text: 'In Process'},
    3: {value: 30, text: 'Acknowledged'},
    4: {value: 40, text: 'Processing'},
    5: {value: 50, text: 'Awaiting Funds'},
    6: {value: 60, text: 'Funds Received'},
    7: {value: 100, text: 'Payment Made'},
    8: {value: 100, text: 'Completed'},
    9: {value: 0, text: 'On Hold'},
    10: {value: 0, text: 'Not Proceeding'}
};

class TransferDetailRemittance extends React.Component {

    selectRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            // register form
            remitting_bank_id: this.props.remitting_bank_id,
            openDialogue: false,
            remitting_bank_account_id: null,
            loaded: false,
            is_loading: true,

            entity_bank: [],
            formInput: {
                date: '',
                decription: '',
                debit: '',
                credit: '',
                dateStatus: null,
                decriptionStatus: null,
                debitStatus: null,
                creditStatus: null
            },
            transfer: {},
            from_balance: false,
            transferDocuments: [],
            transferDocument: null
        };
    }

    toggleDialogue = bool => {
        this.setState({openDialogue: bool});
    };

    componentDidMount() {
        this.setState({
            received_datetime: this.props.received_datetime,
            received_notes: this.props.received_notes,
            from_balance: this.props.from_balance
        });
        this.fetchTransferDocuments();
        // console.log(this.props.remitting_bank_id)
        if (this.state.remitting_bank_id) {
            // console.log(this.state.remitting_bank_id)
            API.get('system', `/entitybanksaccount/get/id/${this.state.remitting_bank_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        entity_bank_account: response[0]
                    });
                    // this.props.loadTransferDetailsSuccess();
                    return response[0];
                })
                .then(response => {
                    // console.log(response);
                    API.get('system', `/entitybanks/get/id/${response.entity_bank_id}`)
                        .then(response => {
                            // console.log(response);
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                entity_bank: response[0]
                            });
                            // this.props.loadTransferDetailsSuccess();
                            API.get('system', `/countries/get/${response[0].address_country}`)
                                .then(response => {
                                    // console.log(response);
                                    this.setState({entity_bank_address_country: response[0].full_name});
                                    // this.props.loadTransferDetailsSuccess();
                                    return response;
                                })
                                .catch(error => {
                                    console.log(error);
                                });

                            return response;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    API.get('system', `/countries/get/${response.address_country}`)
                        .then(response => {
                            // console.log(response);
                            this.setState({entity_bank_account_address_country: response[0].full_name});
                            // this.props.loadTransferDetailsSuccess();
                            return response;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }

        if (this.props.transfer_id) {
            API.get('transfers', `/get-full/id/${this.props.transfer_id}`).then(response => {
                console.log(response.fullList.from_balance, 'a');
                this.setState({
                    transfer: response.fullList,
                    from_balance: response.fullList.from_balance == null ? false : response.fullList.from_balance === 1,
                    payouts_list: response.payouts
                });
            });
        }

        if (this.props.currency_from_id) {
            API.get('system', `/currencies/get/${this.props.currency_from_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({currency_from: response});
                })
                .catch(error => {
                    console.log(error);
                });
        }

        if (this.props.currency_to_id) {
            API.get('system', `/currencies/get/${this.props.currency_to_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({currency_to: response});
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    transferDocumentPreview = async (file_ref) => {
        const url = await Storage.get(file_ref, {expiry: 600});
        const fileInfo = await AppUtil.getFileInfo(url);
        this.setState({ transferDocument: { url: url, fileType: fileInfo.fileType }});
    }

    fetchTransferDocuments = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'transferDocuments',
                fields: ['*'],
                condition: {transferId: this.props.transfer_id}
            }
        }).then(res => {
            if (res.length > 0) {
                let mergedDocuments = [];
                const documents = res.map(item => item.documentId);
                mergedDocuments = [...mergedDocuments, ...documents];
                console.log(documents);
                res.forEach(item => {
                    const parsed = JSON.parse(item.attachments);
                    const attachments = item.attachments  != null && parsed.ids != null && parsed.ids.length > 0 ? parsed.ids : [];
                    mergedDocuments = [...mergedDocuments,...attachments];
                });
                mergedDocuments = [...new Set(mergedDocuments.filter(i => i !== null))];
                if (mergedDocuments.length > 0) {
                    API.post("commons", "/fetch", {
                        body: {
                            context: 'documents',
                            fields: ['*'],
                            condition: {id: mergedDocuments}
                        }
                    }).then((res) => {
                        this.setState({ transferDocuments: res});
                    }).catch(err => {
                        console.log(err);
                        message.error("Failed to fetch transfer documents");
                    })
                } else {
                    message.info("Cannot find any documents on this transfer");
                }
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.remitting_bank_id !== this.props.remitting_bank_id) {
            API.get('system', `/entitybanksaccount/get/id/${this.props.remitting_bank_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        loaded: true,
                        remitting_bank_id: this.props.remitting_bank_id,
                        is_loading: false,
                        entity_bank_account: response[0]
                    });
                    // this.props.loadTransferDetailsSuccess();
                    return response[0];
                })
                .then(response => {
                    console.log(response);
                    API.get('system', `/entitybanks/get/id/${response.entity_bank_id}`)
                        .then(response => {
                            // console.log(response);
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                entity_bank: response[0]
                            });
                            // this.props.loadTransferDetailsSuccess();
                            return response;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        if (prevProps.currency_from_id !== this.props.currency_from_id) {
            API.get('system', `/currencies/get/${this.props.currency_from_id}`)
                .then(response => {
                    // console.log(response);
                    this.setState({currency_from: response});
                })
                .catch(error => {
                    console.log(error);
                });
        }
        if (prevProps.language_id !== this.props.language_id) {
            API.get('admin', '/languages/get')
                .then(response => {
                    // console.log(response);
                    // console.log(response[this.props.language_id].nickname);
                    // let matched_id = 1;
                    let matched_nickname = 'English';
                    for (const item in response) {
                        if (response[item].id === this.props.language_id) {
                            matched_nickname = response[item].nickname;
                        }
                    }
                    this.setState({language_nickname: matched_nickname});
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getFullDate = s => {
        //console.log(s);
        let showDate = '';
        if (s.getMonth() >= 9) {
            if (s.getDate() > 9) {
                showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
            } else {
                showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
            }
        } else if (s.getMonth() < 9) {
            if (s.getDate() > 9) {
                showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
            } else {
                showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
            }
        }
        return showDate;
    };
    getFullDateTime = s => {
        //console.log(s);
        let showDateTime = '';
        if (s.getMonth() >= 9) {
            if (s.getDate() > 9) {
                showDateTime =
                    s.getFullYear() +
                    '-' +
                    (s.getMonth() + 1) +
                    '-' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            } else {
                showDateTime =
                    s.getFullYear() +
                    '-' +
                    (s.getMonth() + 1) +
                    '-0' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            }
        } else if (s.getMonth() < 9) {
            if (s.getDate() > 9) {
                showDateTime =
                    s.getFullYear() +
                    '-0' +
                    (s.getMonth() + 1) +
                    '-' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            } else {
                showDateTime =
                    s.getFullYear() +
                    '-0' +
                    (s.getMonth() + 1) +
                    '-0' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            }
        }
        return showDateTime;
    };

    async saveTransferDetail(transfer_detail) {
        return API.put('transfers', `/update/id/${this.props.transfer_id}`, {body: transfer_detail});
    }

    handleChange = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleCustomReactSelectChange = (
        name
        // index,
        // previous_from_currency,
        // previous_to_currency
    ) => value => {
        let option_value;
        let option_label;

        if (value === null) {
            option_value = null;
            option_label = null;
        } else {
            option_value = value.value;
            option_label = value.label;
        }

        if (name === 'account_currency' || name == 'to_currency') {
            const currency_dataset = this.state.currencies_list_priority;
            for (let i = 0; i < currency_dataset.length; i++) {
                if (currency_dataset[i].id === option_value) {
                    //iban check
                    if (currency_dataset[i].iban === 1) {
                        this.setState({show_iban: true});
                    } else {
                        this.setState({show_iban: false});
                    }
                    //bsb check
                    if (currency_dataset[i].bsb === 1) {
                        this.setState({show_bsb: true});
                    } else {
                        this.setState({show_bsb: false});
                    }
                    //sort_code check
                    if (currency_dataset[i].sort_code === 1) {
                        this.setState({show_sort_code: true});
                    } else {
                        this.setState({show_sort_code: false});
                    }
                    //swift_code check
                    if (currency_dataset[i].swift_code === 1) {
                        this.setState({show_swift_code: true});
                    } else {
                        this.setState({show_swift_code: false});
                    }
                    //aba_routing check
                    if (currency_dataset[i].aba_routing === 1) {
                        this.setState({show_aba: true});
                    } else {
                        this.setState({show_aba: false});
                    }
                    //account number check
                    if (currency_dataset[i].account_number === 1) {
                        this.setState({show_account_number: true});
                    } else {
                        this.setState({show_account_number: false});
                    }
                }
            }
        }

        if (name == 'payoutBankId') {
            this.setState(
                {
                    currentRecord: {
                        ...this.state.currentRecord,
                        [name]: option_value
                    }
                },
                () => {
                    if (name === 'from_currency' || name === 'to_currency') {
                        switch (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock) {
                            case 'from_amount':
                                this.handleAmount('to_amount');
                                break;
                            case 'to_amount':
                                this.handleAmount('from_amount');
                                break;
                            default:
                                this.handleAmount();
                        }
                    }
                }
            );
        } else {
            this.setState({[name]: option_value}, () => {
                if (name === 'from_currency' || name === 'to_currency') {
                    switch (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock) {
                        case 'from_amount':
                            this.handleAmount('to_amount');
                            break;
                        case 'to_amount':
                            this.handleAmount('from_amount');
                            break;
                        default:
                            this.handleAmount();
                    }
                }
            });
        }
    };
    // End of test block

    onClose = () => {
        this.toggleDialogue(false);
    };

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({isLoading: true});

        try {
            const payload = {
                received_notes: this.state.received_notes
            }
            if (this.state.received_datetime != null) {
                payload.received_datetime = this.state.received_datetime;
            }
            await this.saveTransferDetail(payload);
        } catch (e) {
            alert(e);
            // this.setState({ isLoading: false });
        }
    };

    onSubmit = async () => {
        console.info('remitting_bank_account_id', this.state.remitting_bank_account_id);
        API.get('system', `/entitybanksaccount/get/id/${this.state.remitting_bank_account_id}`)
            .then(response => {
                // console.log(response);
                this.setState({
                    loaded: true,
                    is_loading: false,
                    entity_bank_account: response[0]
                });
                // this.props.loadTransferDetailsSuccess();
                return response[0];
            })
            .then(async prev_response => {
                // console.log(prev_response);
                API.get('system', `/entitybanks/get/id/${prev_response.entity_bank_id}`)
                    .then(async response => {
                        this.setState({
                            loaded: true,
                            is_loading: false,
                            entity_bank: response[0],
                            remitting_bank_id: prev_response.id
                        });
                        // this.props.loadTransferDetailsSuccess();
                        await this.saveTransferDetail({
                            remitting_bank_account_id: this.state.remitting_bank_account_id
                        });
                        this.setState({openDialogue: false});
                        return response;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };

    onFromBalanceChange = event => {
        this.setState({from_balance: event.target.checked});
        const payload = {
            clientId: this.props.client_id,
            isChecked: event.target.checked,
            transferId: this.props.transfer_id
        };
        API.post('transfers', '/clients/from-balance', {body: payload})
            .then(response => {
                console.log(response);
            })
            .catch(err => {
                this.setState({from_balance: !event.target.checked});
                console.log(err);
            });
    };

    removeRemittingBank = () => {
            TransferAPI.updateTransferById(this.props.transfer_id, {remitting_bank_account_id: ""}).then(() => {
                this.setState({ remitting_bank_account_id: null, entity_bank_account: null });
                if (this.selectRef.current != null) {
                    this.selectRef.current.setFieldValue('remitting_bank_account_id_crm', "");
                }
            }).catch(res => {
                console.log(res);
                console.log("Failed to remove remitting bank. Please try again.");
            });
    }

    renderRemittance = () => {
        const {classes} = this.props;

        let adjusted_remittance_reference;
        if (this.state.entity_bank_account.reference_mandatory) {
            adjusted_remittance_reference = this.state.entity_bank_account.reference_mandatory;
        } else if (this.state.entity_bank_account.reference_prefix) {
            adjusted_remittance_reference =
                this.state.entity_bank_account.reference_prefix + this.props.client_id.toString().padStart(6, '0');
        } else {
            adjusted_remittance_reference = 'FS' + this.props.client_id.toString().padStart(6, '0');
        }

        const remittanceTotal = Number(this.props.amount_from);
        const feeVisibleAmount = Number(this.props.fee_visible_amount);
        const totalRemittance = remittanceTotal + feeVisibleAmount;

        return (
            <Card>
                <CardHeader>
                    <h4 className={classes.cardIconTitle}>Remittance Information</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            {/* <Checkbox onChange={this.onFromBalanceChange} checked={this.state.from_balance}>
                                {' '}
                                From Balance {this.state.from_balance}
                            </Checkbox> */}
                            <p>Your foreign exchange transaction is now in process and awaiting settlement.</p>
                            <p>
                                We confirm the exchange rate of{` `}
                                <strong>
                                    {Number(this.props.rate).toFixed(4)}
                                    {` `}
                                </strong>
                                and the amount of {` `}
                                <strong>
                                    {Number(this.props.amount_to).toFixed(2)}
                                    {` `}
                                    <img
                                        src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_to.iso_alpha_3.toLowerCase()}.png`}
                                        width='24px'
                                        height='16px'
                                        style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                    />
                                    {` `}
                                    {this.state.currency_to.full_name} {` `}
                                </strong>
                                will be credited to your nominated beneficiary.
                            </p>
                            <p>
                                Please remit your {` `}
                                <strong>
                                    {totalRemittance.toFixed(2)}
                                    {` `}
                                    <img
                                        src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                        width='24px'
                                        height='16px'
                                        style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                    />
                                    {` `}
                                    {this.state.currency_from.full_name}
                                    {` `}
                                </strong>
                                to our Client Settlement Account.{` `}
                                {(feeVisibleAmount === 1) && (
                                    <>
                                        This amount includes the conversion amount of {remittanceTotal.toFixed(2)} and a
                                        fee of {feeVisibleAmount.toFixed(2)}.
                                    </>
                                )}
                            </p>
                        </GridItem>
                    </GridContainer>
                    <hr />
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <table
                                style={{
                                    width: '100%',
                                    border: '1px solid #a1a1a1'
                                }}
                            >
                                <tbody>
                                    <tr className={classes.backgroundBlue}>
                                        <th className={classes.tableHeaders}>Deposit Amount</th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                <strong>
                                                    {totalRemittance.toFixed(2)}
                                                    {` `}
                                                    <div
                                                        className={`currency-flag currency-flag-${this.state.currency_from.iso_alpha_3.toLowerCase()}`}
                                                    />
                                                    {` `}
                                                    {this.state.currency_from.full_name}
                                                </strong>
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h5 className={classes.pageSubHeadings} style={{marginTop: 24}}>
                                Bank Account Details
                            </h5>
                            <table
                                style={{
                                    width: '100%',
                                    border: '1px solid #a1a1a1'
                                }}
                            >
                                <tbody>
                                    <tr className={classes.backgroundBlue}>
                                        <th className={classes.tableHeaders}>Account Name</th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                <strong>{this.state.entity_bank_account.eba_accout_name}</strong>
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={classes.tableHeaders}>Account Address</th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                {this.state.entity_bank_account.address_line_1}
                                                {` `}
                                                {this.state.entity_bank_account.address_line_2}
                                                {` `}
                                                {this.state.entity_bank_account.address_suburb}
                                                {` `}
                                                {this.state.entity_bank_account.address_state}
                                                {` `}
                                                {this.state.entity_bank_account.address_postcode}
                                                <br />
                                                {this.state.entity_bank_account.entity_bank_account_address_country}
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                    <tr className={classes.backgroundBlue}>
                                        <th className={classes.tableHeaders}>Bank Name</th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                <strong>{this.state.entity_bank.eb_long_name}</strong>
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={classes.tableHeaders}>Bank Address</th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                {this.state.entity_bank.address_line_1}
                                                {` `}
                                                {this.state.entity_bank.address_line_2}
                                                {` `}
                                                {this.state.entity_bank.address_suburb}
                                                {` `}
                                                {this.state.entity_bank.address_state}
                                                {` `}
                                                {this.state.entity_bank.address_postcode}
                                                <br />
                                                {this.state.entity_bank.entity_bank_account_address_country}
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                    {this.state.entity_bank_account.eba_swift && (
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>SWIFT Code</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>
                                                    {this.state.entity_bank_account.eba_swift}
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank_account.eba_sortcode && (
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Sort Code</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>
                                                    {this.state.entity_bank_account.eba_sortcode}
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank_account.eba_bsb && (
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>BSB</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>
                                                    {this.state.entity_bank_account.eba_bsb}
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank_account.aba_routing_number && (
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>ABA Routing</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>
                                                    {this.state.entity_bank_account.aba_routing_number}
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank_account.eba_accout_number && (
                                        <tr>
                                            <th className={classes.tableHeaders}>Account Number</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>
                                                    {this.state.entity_bank_account.eba_accout_number}
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank_account.iban && (
                                        <tr>
                                            <th className={classes.tableHeaders}>IBAN</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>{this.state.entity_bank_account.iban}</React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank.intermediary_bank && (
                                      <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Intermediary Bank</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>{this.state.entity_bank.intermediary_bank}</React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank.intermediary_address && (
                                        <tr>
                                            <th className={classes.tableHeaders}>Intermediary Bank Address</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>{this.state.entity_bank.intermediary_address}</React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.entity_bank.intermediary_swift && (
                                      <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Intermediary Bank Swift</th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>{this.state.entity_bank.intermediary_swift}</React.Fragment>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <br />
                            <table
                                style={{
                                    width: '100%',
                                    border: '1px solid #a1a1a1'
                                }}
                            >
                                <tbody>
                                    <tr className={classes.backgroundBlue}>
                                        <th className={classes.tableHeaders}>Mandatory Reference</th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                <strong>{adjusted_remittance_reference}</strong>
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </GridItem>
                    </GridContainer>
                    {
                        this.state.entity_bank != null ?
                            <Button
                                // color='secondary'
                                type='button'
                                color={'danger'}
                                className={classes.updateProfileButton}
                                disabled={!this.props.edit_mode}
                                onClick={() => this.removeRemittingBank()}
                            >
                                Remove
                            </Button> : ''
                    }
                    <Button
                        // color='secondary'
                        type='button'
                        className={classes.updateProfileButton}
                        disabled={!this.props.edit_mode}
                        onClick={() => this.toggleDialogue(true)}
                    >
                        Change
                    </Button>
                </CardBody>
            </Card>
        );
    };

    renderRemittance_ledgerFrom = () => {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <GridContainer>
                    {/* <GridItem xs={12} sm={12} md={12}>
            <HorizontalStepper transfer_status={this.props.transfer_status} />
          </GridItem> */}
                    <GridItem xs={12} sm={12} md={12}>
                        <Card style={{height: '90%'}}>
                            <CardHeader>
                                <h4 className={classes.cardIconTitle}>Remittance Information</h4>
                            </CardHeader>
                            <CardBody>
                                <p>Source funds are from Holding Account ID: #{this.props.ledgerFrom}.</p>
                                <p>No payment is required to process this transfer and it is now proceeding.</p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        );
    };

    renderRemittance_nullRemittingBankId = () => {
        const {classes} = this.props;
        if (!this.state.currency_to || !this.state.currency_from) {
            return null;
        }

        const remittanceTotal = Number(this.props.amount_from);
        const feeVisibleAmount = Number(this.props.fee_visible_amount);
        const totalRemittance = remittanceTotal + feeVisibleAmount;

        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card style={{height: '90%'}}>
                                <CardHeader>
                                    <h4 className={classes.cardIconTitle}>Remittance Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <p>
                                                <strong>
                                                    NOTE: No remitting account has been selected.<br />
                                                </strong>
                                                The system is defaulting to draw from currency balance.
                                            </p>
                                            <p>
                                                Your foreign exchange transaction is now in process and awaiting
                                                settlement.
                                            </p>
                                            <p>
                                                We confirm the exchange rate of{` `}
                                                <strong>
                                                    {Number(this.props.rate).toFixed(4)}
                                                    {` `}
                                                </strong>
                                                and the amount of {` `}
                                                <strong>
                                                    {Number(this.props.amount_to).toFixed(2)}
                                                    {` `}
                                                    <img
                                                        src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_to.iso_alpha_3.toLowerCase()}.png`}
                                                        width='24px'
                                                        height='16px'
                                                        style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                                    />
                                                    {` `}
                                                    {this.state.currency_to.full_name}
                                                    {` `}
                                                </strong>
                                                will be credited to your nominated beneficiary.
                                            </p>
                                            <p>
                                                The amount of {` `}
                                                <strong>
                                                    {totalRemittance.toFixed(2)}
                                                    {` `}
                                                    <img
                                                        src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                        width='24px'
                                                        height='16px'
                                                        style={{verticalAlign: 'middle', marginBottom: '4px'}}
                                                    />
                                                    {` `}
                                                    {this.state.currency_from.full_name}
                                                    {` `}
                                                </strong>
                                                will be drawn from your Currency Balance to fund this transaction and credited to your nominated beneficiary.{` `}
                                                {feeVisibleAmount > 0 && (
                                                    <>
                                                        This amount includes the conversion amount of{' '}
                                                        {remittanceTotal.toFixed(2)} and a fee of{' '}
                                                        {feeVisibleAmount.toFixed(2)}.
                                                    </>
                                                )}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                    <Button
                                        color='secondary'
                                        type='button'
                                        className={classes.updateProfileButton}
                                        disabled={!this.props.edit_mode}
                                        onClick={() => this.toggleDialogue(true)}
                                    >
                                        Change
                                    </Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {this.props.no_admin ? null : (
                            <GridItem xs={12} sm={12} md={6}>
                                {/* <Card style={{ height: '90%' }}>
                            <CardHeader>
                                <h6>Administration</h6>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        Date payment received:
                                        <br />
                                        <FormControl fullWidth>
                                            <Datetime
                                                closeOnSelect={true}
                                                inputProps={{
                                                    disabled: !this.props
                                                        .edit_mode,
                                                    placeholder: this.getFullDate(
                                                        new Date(
                                                            this.state.payout_datetime
                                                        )
                                                    ),
                                                    //value: this.getFullDate(new Date(this.state.transaction_datetime)),
                                                }}
                                                onChange={event => {
                                                    let selDate = this.getFullDateTime(
                                                        new Date(
                                                            event.toDate()
                                                        )
                                                    )
                                                    this.setState({
                                                        payout_datetime: selDate,
                                                    })
                                                }}
                                            />
                                        </FormControl>
                                    </GridItem>
                                </GridContainer> */}
                                {/* <GridContainer>
                <GridItem xs={12}>
                  <h6>Communication</h6>
                  <TransferDetailRemittanceCommunication
                    id={this.props.client_id}
                    rate={this.props.rate}
                    amount_to={this.props.amount_to}
                    currency_to={this.state.currency_to.full_name}
                    currency_to_iso3={this.state.currency_to.iso_alpha_3}
                    amount_from={this.props.amount_from}
                    currency_from={this.state.currency_from.full_name}
                    currency_from_iso3={this.state.currency_from.iso_alpha_3}
                    eba_account_name={this.state.entity_bank_account.eba_accout_name}
                    enabled={true}
                    remitting_bank_id={this.props.remitting_bank_id}
                    remittance_reference={remittance_reference}
                  />
                </GridItem>
              </GridContainer> */}
                                {/* </CardBody> */}
                                {/* </Card> */}
                            </GridItem>
                        )}
                    </GridContainer>
                    {/*<Button*/}
                    {/*  color="primary"*/}
                    {/*  type="submit"*/}
                    {/*  className={classes.updateProfileButton}*/}
                    {/*  disabled={!this.props.edit_mode}*/}
                    {/*>*/}
                    {/*  Update Transfer*/}
                    {/*</Button>*/}
                </form>
            </React.Fragment>
        );
    };

    renderAdmin = () => {
        const {classes} = this.props;
        if (!this.props.app_state.current_staff) return null;

        let adjusted_remittance_reference;
        if (this.state.entity_bank_account && this.state.entity_bank_account.reference_mandatory) {
            adjusted_remittance_reference = this.state.entity_bank_account.reference_mandatory;
        } else if (this.state.entity_bank_account && this.state.entity_bank_account.reference_prefix) {
            adjusted_remittance_reference =
                this.state.entity_bank_account &&
                this.state.entity_bank_account.reference_prefix + this.props.client_id.toString().padStart(6, '0');
        } else {
            adjusted_remittance_reference = this.props.client_id.toString().padStart(6, '0');
        }

        let eba_account_name = this.state.entity_bank_account ? this.state.entity_bank_account.eba_accout_name : null;

        return (
            <Card>
                <CardHeader>
                    <h4 className={classes.cardIconTitle}>BackOffice</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            <TransferDetailRemittanceCommunication
                                id={this.props.client_id}
                                transferId={this.props.transfer_id}
                                currentStaffID={this.props.app_state.current_staff.id}
                                rate={this.props.rate}
                                settlementDate={this.props.settlementDate}
                                amount_to={this.props.amount_to}
                                currency_to={this.state.currency_to.full_name}
                                amount_from={this.props.amount_from}
                                currency_from={this.state.currency_from.full_name}
                                eba_account_name={eba_account_name}
                                enabled={true}
                                remitting_bank_id={this.state.remitting_bank_id}
                                remittance_reference={adjusted_remittance_reference}
                                language_nickname={this.state.language_nickname}
                                language_id={this.props.language_id}
                                emailsent_remittance_date={this.state.transfer.emailsent_remittance_date}
                                emailsent_remittance_staff={this.state.transfer.emailsent_remittance_staff}
                                current_staff_email={this.props.app_state.current_staff.email}
                            />
                        </GridItem>
                    </GridContainer>
                    {/* <hr />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      Monies received by FWW
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          inputProps={{
                            disabled: !this.props.edit_mode,
                            placeholder: this.state.received_datetime
                              ? moment(this.state.received_datetime).format('DD/MM/YYYY')
                              : '',
                            value: this.state.received_datetime
                              ? moment(this.state.received_datetime).format('DD/MM/YYYY')
                              : ''
                          }}
                          onChange={event => {
                            const selDate = this.getFullDateTime(new Date(event.toDate()));
                            this.setState({received_datetime: selDate});
                          }}
                        />
                      </FormControl>
                      <CustomInput
                        labelText='Receipt Notes'
                        id='received_notes'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          disabled: !this.props.edit_mode,
                          value: this.state.received_notes || '',
                          onChange: event => {
                            this.handleChange(event);
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer> */}
                </CardBody>
            </Card>
        );
    };

    renderAdjustRemittingAccountDialog = () => {
        let filteredEntityBanks = this.props.entity_banks_account.entitybanks;
        filteredEntityBanks = filteredEntityBanks.filter(item => item.currency_id === this.props.currency_from_id);

        if (!this.props.app_state.current_staff_super_admin) {
            filteredEntityBanks = filteredEntityBanks.filter(item => !item.monoova || item.clientID === this.props.client_id);
        }

        if (!this.props.app_state.current_staff.backofficeStaff) {
            filteredEntityBanks = filteredEntityBanks.filter(item =>  item.general_access  || item.clientID === this.props.client_id);
        }

        return (
            <Modal open={this.state.openDialogue}
                   onCancel={this.onClose}
                   onOk={() => this.selectRef.current.submit()}
                   cancelButtonProps={{ danger: true, type: 'primary' }}
                   title={'Change Remittance Account'}>
                <GridContainer>
                    <GridItem xs={12} md={12}>
                        <Form ref={this.selectRef} onFinish={this.onSubmit}>
                            <Form.Item labelCol={{  span: 24 }} wrapperCol={{ span: 24 }}
                                       name="remitting_bank_account_id"
                                       rules={[{required: true, message: 'Select remitting bank account'}]}
                                       label={'Select new remittance account'}>
                                <Select placeholder={'-- Search for remitting bank --'}
                                        value={this.state.remitting_bank_account_id} onChange={(e) => this.setState({ remitting_bank_account_id: e})}
                                        options={filteredEntityBanks
                                            .sort((a, b) => a.nicknameInternal.localeCompare(b.nicknameInternal))
                                            .map(item => {
                                                return {
                                                    value: item.id,
                                                    label: item.nicknameInternal
                                                };
                                            })}></Select>
                            </Form.Item>
                        </Form>
                    </GridItem>
                </GridContainer>
            </Modal>
        );
    };

    render() {
        const {classes} = this.props;
        // console.log(this.state);
        // console.log(this.props)

        let remittanceComponent = null;

        if (
            // !this.state.loaded ||
            !this.state.currency_from ||
            !this.state.currency_to
        ) {
            return null;
        }

        if (!this.state.entity_bank || !this.state.entity_bank_account) {
            remittanceComponent = this.renderRemittance_nullRemittingBankId();
        } else if (this.props.ledgerFrom) {
            remittanceComponent = this.renderRemittance_ledgerFrom();
        } else {
            remittanceComponent = this.renderRemittance();
        }
        // if (this.props.ledgerFrom) {
        //   return this.renderRemittance_ledgerFrom()
        //   // return (
        //   //   <React.Fragment>
        //   //     <GridContainer>
        //   //       <GridItem xs={12} sm={12} md={12}>
        //   //         <HorizontalStepper transfer_status={this.props.transfer_status} />
        //   //       </GridItem>
        //   //       <GridItem xs={12} sm={12} md={6}>
        //   //         <Card style={{height: '90%'}}>
        //   //           <CardHeader>
        //   //             <h6>Remittance Information</h6>
        //   //           </CardHeader>
        //   //           <CardBody>
        //   //             <p>Source funds are from Holding Account ID: #{this.props.ledgerFrom}.</p>
        //   //             <p>No payment is required to process this transfer and it is now proceeding.</p>
        //   //           </CardBody>
        //   //         </Card>
        //   //       </GridItem>
        //   //     </GridContainer>
        //   //   </React.Fragment>
        //   // );
        // }
        // let remittance_reference = `${this.state.entity_bank_account.reference_prefix}${this.props.client_id}`;
        // let adjusted_remittance_reference;
        // if (this.state.entity_bank_account.reference_mandatory) {
        //   adjusted_remittance_reference = this.state.entity_bank_account.reference_mandatory;
        // } else if (this.state.entity_bank_account.reference_prefix) {
        //   adjusted_remittance_reference = this.state.entity_bank_account.reference_prefix + this.props.client_id;
        // } else {
        //   adjusted_remittance_reference = 'FS' + this.props.client_id.toString().padStart(5, '0');
        // }

        // const entity_bank_list = this.state.entity_bank.map(item => {
        //   return {
        //     value: item.id,
        //     label: item.nickname
        //   };
        // });

        let payout = [{}];
        let payoutComponent;
        if (this.state.payouts_list && this.state.payouts_list.length == 1) {
            payout = this.state.payouts_list[0];
            payoutComponent = (
                <GridItem xs={12}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<span>Name </span>}
                                id='account_number'
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    value: payout.ben_legal_name || 'Payment will be made to your Currency Balance',
                                    disabled: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<span>Address </span>}
                                id='account_number'
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    value: `${payout.ben_address_line_1 || ''} ${payout.ben_address_suburb || ''}`,
                                    disabled: true
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<span>Bank </span>}
                                id='account_number'
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    value: `${payout.bank_legal_name || ''}`,
                                    disabled: true
                                }}
                            />
                            <CustomInput
                                labelText={<span>Address </span>}
                                id='account_number'
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    value: `${payout.bank_address_line_1 || ''} ${payout.bank_address_suburb || ''}`,
                                    disabled: true
                                }}
                            />
                        </GridItem>
                        {payout.swift_code && (
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText={<span>Swift </span>}
                                    id='swift_code'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        value: payout.swift_code,
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                        )}
                        {payout.aba_routing_number && (
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText={<span>ABA </span>}
                                    id='aba_routing_number'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        value: payout.aba_routing_number,
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                        )}
                        {payout.bsb_code && (
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText={<span>BSB Code </span>}
                                    id='bsb_code'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        value: payout.bsb_code,
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                        )}
                        {payout.account_number && (
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText={<span>Account Number </span>}
                                    id='account_number'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        value: payout.account_number,
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                        )}
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12}>
                            <React.Fragment>
                                <label>
                                    <span style={{fontSize: '11px'}}>Amount</span>
                                </label>{' '}
                                <br />
                                <div
                                    className={`currency-flag currency-flag-${
                                        this.state.transfer && this.state.transfer.currency_to_iso_alpha_3
                                            ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                            : ''
                                    }`}
                                />
                                {` `}
                                {this.state.transfer && this.state.transfer.currency_to_iso_alpha_3}
                                {`  `}
                                <FormattedNumber
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    value={this.state.transfer && this.state.transfer.amount_to}
                                />
                            </React.Fragment>
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<span>Purpose </span>}
                                id='account_number'
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    value: `${payout.description} `,
                                    disabled: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<span>Transfer Detail</span>}
                                id='account_number'
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    value: `${payout.detail_1}`,
                                    disabled: true
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            {/* <CustomInput
          labelText={<span>Status </span>}
          id="account_number"
          formControlProps={{fullWidth: true}}
          inputProps={{
            value: parseInt(this.props.transfer_status) *,
            disabled: true
          }}
        /> */}
                            <label>
                                <span style={{fontSize: '11px'}}>Status</span>
                            </label>
                            <div>
                                <CircularProgressbar
                                    className={classes.progressBar}
                                    value={
                                        this.props.isCompleted
                                            ? 100
                                            : TRANSFER_STATUS_COMPLETE_VALUE[this.props.transfer_status].value
                                    }
                                    text={`${
                                        this.props.isCompleted
                                            ? 100
                                            : TRANSFER_STATUS_COMPLETE_VALUE[this.props.transfer_status].value
                                    }%`}
                                    styles={buildStyles({
                                        textColor: '#000',
                                        textSize: '24px',
                                        pathColor: '#632DE6'
                                    })}
                                />
                                <span>{TRANSFER_STATUS_COMPLETE_VALUE[this.props.transfer_status].text}</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            );
        }

        if (this.state.payouts_list && this.state.payouts_list.length > 1) {
            payoutComponent = (
                <GridItem xs={12}>
                    {this.state.payouts_list.map((payout, index) => {
                        return (
                            <>
                                <table
                                    style={{
                                        width: '100%',
                                        border: '1px solid #a1a1a1'
                                    }}
                                >
                                    <tbody>
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Deposit Amount</th>
                                            <td className={classes.tableCells}>{payout.ben_legal_name}</td>
                                        </tr>
                                        <tr>
                                            <th className={classes.tableHeaders}>Address</th>
                                            <td className={classes.tableCells}>
                                                {payout.ben_address_line_1 || ''} {payout.ben_address_suburb || ''}
                                            </td>
                                        </tr>
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Bank</th>
                                            <td className={classes.tableCells}>{payout.bank_legal_name}</td>
                                        </tr>
                                        <tr>
                                            <th className={classes.tableHeaders}>Bank Address</th>
                                            <td className={classes.tableCells}>
                                                {payout.bank_address_line_1 || ''} {payout.bank_address_suburb || ''}
                                            </td>
                                        </tr>
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>SWIFT</th>
                                            <td className={classes.tableCells}>{payout.swift_code}</td>
                                        </tr>
                                        <tr>
                                            <th className={classes.tableHeaders}>ABA Routing</th>
                                            <td className={classes.tableCells}>{payout.aba_routing_number}</td>
                                        </tr>
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>BSB Code</th>
                                            <td className={classes.tableCells}>{payout.bsb_code}</td>
                                        </tr>
                                        <tr>
                                            <th className={classes.tableHeaders}>Account Number</th>
                                            <td className={classes.tableCells}>{payout.account_number}</td>
                                        </tr>
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Amount</th>
                                            <td className={classes.tableCells}>
                                                <div
                                                    className={`currency-flag currency-flag-${
                                                        this.state.transfer &&
                                                        this.state.transfer.currency_to_iso_alpha_3
                                                            ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                                            : ''
                                                    }`}
                                                />
                                                {` `}
                                                {this.state.transfer && this.state.transfer.currency_to_iso_alpha_3}
                                                {`  `}
                                                <FormattedNumber
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    value={payout.amount_to}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.tableHeaders}>Purpose</th>
                                            <td className={classes.tableCells}>{payout.description}</td>
                                        </tr>
                                        <tr className={classes.backgroundBlue}>
                                            <th className={classes.tableHeaders}>Transfer Detail</th>
                                            <td className={classes.tableCells}>{payout.detail_1}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                            </>
                        );
                    })}
                </GridItem>
            );
        }

        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            {this.state.transferDocument != null ? <>
                                <Tag style={{ float: 'right', marginBottom: '10px' }} color={'red'} closeIcon={<CloseOutlined />} onClick={() => this.setState({transferDocument: null})} onClose={() => this.setState({transferDocument: null})}>Close</Tag>
                                {this.state.transferDocument.fileType == null || this.state.transferDocument.fileType === 'pdf'
                                    ? <iframe src={this.state.transferDocument.url} style={{width: '100%', height: '600px' }}/>
                                    : <Image src={this.state.transferDocument.url}></Image>}
                            </> : <></>}
                            {remittanceComponent}
                            {this.renderAdmin()}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className={classes.cardIconTitle}>Payout Details</h4>
                                </CardHeader>
                                <CardBody>
                                    <Collapse size={'small'} items={[
                                        {
                                            label: 'Invoices',
                                            children: <>
                                                <Flex gap={3}>
                                                    <Row gutter={[16, 16]}>
                                                        {this.state.transferDocuments.map(i => {
                                                            return <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                                                                <FileUploader uploaderType={'PDF'} previewOnly={true} thumbType={'picture-card'}
                                                                              fetchFileList={() => {
                                                                                  const files = [];
                                                                                  if (i != null && i.file_ref != null) {
                                                                                      const file = {
                                                                                          filename: i.file_ref,
                                                                                          name: i.file_ref,
                                                                                          uid: i.file_ref,
                                                                                          status: "done"
                                                                                      }
                                                                                      files.push(file);
                                                                                  }
                                                                                  return Promise.resolve(files);
                                                                              }}></FileUploader>
                                                            </Col>
                                                        })}
                                                    </Row>
                                                </Flex>
                                            </>
                                        }
                                    ]}></Collapse>
                                    <Divider style={{ marginBottom: 5 }} />
                                    <GridContainer>{payoutComponent}</GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                color='primary'
                                type='submit'
                                className={classes.updateProfileButton}
                                disabled={!this.props.edit_mode}
                            >
                                Update Transfer
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
                {this.renderAdjustRemittingAccountDialog()}
            </React.Fragment>
        );
    }
}

// TransferDetailRemittance.propTypes = {classes: PropTypes.object.isRequired};

// export default withStyles(styles)(TransferDetailRemittance);
const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        entity_banks_account: state.entity_banks_account

        // transfers_dashboard: state.transfers_dashboard
    };
};

const mapDispatchToProps = dispatch => {
    return {fetchEntitybanksListNotDeleted: dispatch(fetchEntitybanksListNotDeleted())};
};

const TransferDetailRemittanceContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TransferDetailRemittance));

export default TransferDetailRemittanceContainer;
