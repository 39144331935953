import React, {useEffect, useState} from 'react';

import {Descriptions, Flex, Typography} from 'antd';
import {API} from "aws-amplify";


const ClientSummary2 = ({client}) => {

    // console.log(client);

    const [componentState, setComponentState] = useState({
        staffList: [],
        teamList: [],
        statusList: []
    });

    const fetchStaffMembers = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'staff_members',
                fields: ['*']
            }
        }).then(res => {
            // console.log(res);
            setComponentState(prev => ({...prev, staffList: res}));
        })
    }

    const fetchClientStatus = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'admin_client_status',
                fields: ['*'],
            }
        }).then(res => {
            // console.log(res);
            setComponentState(prev => ({...prev, statusList: res}));
        })
    }

    const fetchTeams = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'teams',
                fields: ['*'],
                deleted: false
            }
        }).then(res => {
            // console.log(res);
            setComponentState(prev => ({...prev, teamList: res}));
        })
    }

    const renderTeam = (teamId) => {
        const team = componentState.teamList.find(i => i.id === teamId);
        if (team != null) {
            return team.nickname;
        }
        return '-'
    }

    const renderStatus = (statusId) => {
        const status = componentState.statusList.find(i => i.id === statusId);
        if (status != null) {
            return status.nickname;
        }
        return '-'
    }

    const renderStaff = (staffId) => {
        const staff = componentState.staffList.find(i => i.id === staffId);
        if (staff != null) {
            return <Flex vertical>
                <Typography.Text>{staff.nickname}</Typography.Text>
                <Typography.Text>{staff.email}</Typography.Text>
            </Flex>
        }
        return '-'
    }

    useEffect(() => {
        fetchTeams();
        fetchStaffMembers();
        fetchClientStatus();
    }, []);
    return <>
        <Descriptions bordered={true} size={'small'} column={1} items={[
            {
                label: 'Account Type',
                children: <>
                    {
                        (client.account_type === 1 || client.account_type === null) ? 'Individual' : "Business"
                    }
                </>
            },
            {
                label: 'Responsible Staff Member',
                children: <>{renderStaff(client.responsible_staff_member)}</>
            },
            {label: 'Team', children: <>{renderTeam(client.team_id)}</>},
            {label: 'Client Status', children: <>{renderStatus(client.account_status)}</>},
        ]}></Descriptions>
    </>
};

export default ClientSummary2;