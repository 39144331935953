import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Flex,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Table,
    Typography
} from "antd";
import {connect} from "react-redux";
import TypographyCountry from "../CurrencySelect/TypographyCountry";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {useForm} from "antd/es/form/Form";
import {SaveOutlined} from "@ant-design/icons";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";
import ListTable from "../ListTable/ListTable";

const TeamPurposeOfPayment = (props) => {

    const [form] = useForm();

    const  [componentState, setComponentState] = useState({
        teamClientList: [],
        currencyList: [],
        isModalOpen: false
    });

    const fetchCurrencies = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: false},
            }
        }).then(res => {
            setComponentState(prev => ({...prev, currencyList: res }));
        })
    }

    const fetchClientTeams = () => {
        API.get('clients', `/getByTeam/id/${props.app_state.current_staff.team_id}`)
            .then(response => {
                console.log(response);
                setComponentState(prev => ({
                    ...prev,
                    teamClientList: response
                }));
            })
            .catch(error => {
                console.log(error);
            });
    }

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                sorter: (a,b) => a.id - b.id,
                defaultSortOrder: ['desc'],
                render: (text) => text
            },
            {
                key: 'description',
                title: 'Description',
                dataIndex: 'description',
                ...props.getColumnSearchProps({
                    dataIndex: 'description',
                    render: text => text
                })
            },
            {
                key: 'actions',
                title: 'Action',
                dataIndex: 'actions'
            }
        ]
    }

    useEffect(() => {
        if (props.app_state && props.app_state.current_staff != null) {
            console.log(props.state)
            fetchClientTeams();
        }
    }, [props.app_state]);

    return <>
        <Card size={'small'} title={'Purpose Of Payments'}>
            <Button type={'primary'} style={{ marginBottom: 10 }} onClick={() => setComponentState(prev => ({...prev, isModalOpen: true }))}>Create New Records</Button>
            <Table size={'small'}
                   columns={buildColumns()}
                   dataSource={props.state.purposeOfPayments.map(prop => {
                    return {
                        id: prop.id ? prop.id : null,
                        active: prop.active ? prop.active : null,
                        description: prop.description ? prop.description : null,
                        actions: (
                            <Switch defaultChecked={prop.active === 1} onClick={(e) => props.updateState(prop, prop.active ? !prop.active : true)}/>
                        )
                    };
                })
            }></Table>
            <Modal open={componentState.isModalOpen}
                   onOk={() => form.submit()}
                   onCancel={() => {
                       form.resetFields();
                       setComponentState(prev => ({
                           ...prev,
                           isModalOpen: false
                       }))
                   }}
                   title={'Add New Purpose Of Payment'}>
                <Divider />
                <Form name={'purposeOfPayment-form'} form={form} onFinish={(values) => props.savePurposeOfPayment(values)}>
                    <Flex justify={'start'} vertical>
                        <Form.Item name={'pOpDescription'} label={'Description'} valuePropName={'checked'}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'pOp_active'} label={'Active'} valuePropName={'checked'}>
                            <Switch />
                        </Form.Item>
                    </Flex>
                </Form>
            </Modal>
        </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ListTable(TeamPurposeOfPayment));