import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Flex,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Table,
    Typography
} from "antd";
import {connect} from "react-redux";
import TypographyCountry from "../CurrencySelect/TypographyCountry";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {useForm} from "antd/es/form/Form";
import {SaveOutlined} from "@ant-design/icons";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";
import ListTable from "../ListTable/ListTable";
import {CurrencyLocales} from "../../AppUtil";

const TeamFees = (props) => {

    console.log("props", props);

    const [form] = useForm();

    const  [componentState, setComponentState] = useState({
        currencyList: [],
        isModalOpen: false
    });

    const fetchCurrencies = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: false},
            }
        }).then(res => {
            setComponentState(prev => ({...prev, currencyList: res }));
        })
    }

    const buildColumns = () => {
        return [
            {
                key: 'currencies_short_name',
                title: 'ID',
                dataIndex: 'currencies_short_name',
                defaultSortOrder: ['desc'],
                render: (text) => <TypographyCurrency iso_alpha_3={text}>{text}</TypographyCurrency>
            },
            {
                key: 'transferAmount',
                title: 'Transfer Amount',
                dataIndex: 'transferAmount',
                ...props.getColumnSearchProps({
                    dataIndex: 'transferAmount',
                    render: (text, record) => new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: record.currencies_short_name,
                    }).format(text)
                })
            },
            {
                key: 'feeAmount',
                title: 'Fee Amount',
                dataIndex: 'feeAmount',
                ...props.getColumnSearchProps({
                    dataIndex: 'feeAmount',
                    render: (text, record) => new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: record.currencies_short_name,
                    }).format(text)
                })
            },
            {
                key: 'feeDescription',
                title: 'Description',
                dataIndex: 'feeDescription',
                ...props.getColumnSearchProps({
                    dataIndex: 'feeDescription',
                    render: text => text
                })
            }
        ]
    }

    const handleSubmit = (values) => {
        API.post('teams', '/create/team_transfer', {
            body: {
                teamId: props.teamId,
                ...values
            }
        }).then(res => {
            props.updateState(null);
            setComponentState(prev => ({...prev, isModalOpen: false}));
            form.resetFields();
        })
    }

    useEffect(() => {
        fetchCurrencies();
    }, []);

    return <>
        <Card size={'small'} title={'Team Fees'}>
            <Button type={'primary'} style={{ marginBottom: 10 }} onClick={() => setComponentState(prev => ({...prev, isModalOpen: true }))}>Create New</Button>
            <Table size={'small'}
                   columns={buildColumns()}
                   dataSource={props.state.transferFee}></Table>
            <Modal open={componentState.isModalOpen}
                   onOk={() => form.submit()}
                   onCancel={() => {
                       form.resetFields();
                       setComponentState(prev => ({
                           ...prev,
                           isModalOpen: false
                       }))
                   }}
                   title={'Add New Record'}>
                <Divider />
                <Form name={'purposeOfPayment-form'} form={form} onFinish={handleSubmit}>
                    <Form.Item name={'currencyId'} label={'Currency'}>
                        <Select placeholder={'-- SELECT --'} optionFilterProp={'alt'}
                                options={(componentState.currencyList || []).map(i => ({
                                    label: <TypographyCurrency
                                        iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>,
                                    value: i.id,
                                    alt: `${i.full_name}-${i.iso_alpha_3}`
                                }))}></Select>
                    </Form.Item>
                    <Form.Item name={'transferAmount'} label={'Transfer Amount'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'feeAmount'} label={'Fee Amount'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'feeDescription'} label={'Description'}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ListTable(TeamFees));