import React, {useEffect, useState} from 'react';

import {Descriptions, Divider} from 'antd';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';

import dayjs from 'dayjs';
import {API} from "aws-amplify";


const ClientSummary1 = ({client}) => {

  // console.log(client);

  const [componentState, setComponentState] = useState({
    countryList: []
  });

  const fetchCountries = () => {
    API.post("commons", "/fetch", {
      body: {
        context: 'countries',
        fields: ['*'],
        condition: {deleted: false}
      }
    }).then(res => {
      // console.log(res);
      setComponentState(prev => ({...prev, countryList: res}));
    })
  }

  const renderCountry = (countryId) => {
    const country = componentState.countryList.find(i => i.id === countryId);
    if (country != null) {
      return country.full_name;
    }
    return ''
  }

  useEffect(() => {
    fetchCountries()
  }, []);
  return <>
    <Descriptions bordered={true} size={'small'} column={1} items={[
      {
        label: 'Client',
        children: <>
          <strong>{client.client_nickname}</strong>
          {
            client.business_company_name ?
              <span>
                {client.business_company_number ? <span><br/>ACN: {client.business_company_number}</span> : null}
                {client.business_abn ? <span><br/>ABN: {client.business_abn}</span> : null}
              </span>
              : null
          }
        </>
      },
      client.business_company_name ?
      {
        label: 'Contact Name',
        children: <>
          {client.first_name}{` `}
          {client.middle_name}{` `}
          {client.last_name}
        </>
      } : {},
      {label: 'Email', children: <>{client.email}</>},
      {
        label: 'Telephone',
        children: <>
          {
            client.telephone_mobile ? client.telephone_mobile :
              client.telephone_work ? client.telephone_work :
                client.telephone_home
          }
        </>
      }
    ]}></Descriptions>
    <Divider style={{marginTop: 10, marginBottom: 10}}/>
    <GridContainer>
      <GridItem md={6}>
        <Descriptions bordered={true} size={'small'} column={1} items={[
          {
            label: 'Address',
            children: <>
              {client.residential_street_line_1}<br/>
              {client.residential_street_line_2 ? <span>{client.residential_street_line_2}<br/></span> : null}
              {client.residential_street_suburb}{` `}
              {client.residential_street_state}{` `}
              {client.residential_street_postcode} {renderCountry(client.residential_street_country)}<br/>
            </>
          }
        ]}></Descriptions>
      </GridItem>
      <GridItem md={6}>
        <Descriptions bordered={true} size={'small'} column={1} items={[
          {
            label: 'Date of Birth',
            children: <>
              {client.date_of_birth ?
                dayjs(client.date_of_birth).isBefore(dayjs("01/01/1900")) ?
                  "N/A" :
                  dayjs(client.date_of_birth).format("DD/MM/YYYY")
                : null}
            </>
          },
          {
            label: 'Place of Birth',
            children: <>
              {client.place_of_birth_city} {renderCountry(client.residential_street_country)} <br/>
            </>
          }
        ]}></Descriptions>
      </GridItem>
    </GridContainer>
    <Divider style={{marginTop: 10, marginBottom: 10}}/>
    <Descriptions bordered={true} size={'small'} column={1} items={[
      {label: 'Client Notes', children: <>{client.clientNotes}</>},
      {label: 'Webpage', children: <>{client.webpage}</>}
    ]}></Descriptions>


  </>
};

export default ClientSummary1;